<template>
  <div class="flex justify-center h-full w-full">
    <div class="w-full sm:w-max-2xl">
      <div v-if="store" class="w-full flex flex-col gap-4">
        <p class="text-2xl font-bold text-center w-full">Update your store</p>
        <vs-input block v-model="name" placeholder="Store Name">
          <template #icon>
            <i class="bx bx-text" />
          </template>

          <template v-if="name && name.length < 4" #message-danger>
            <div class="text-red-500">
              Store name must be at least 4 characters
            </div>
          </template>
          <template v-if="name && name.length > 20" #message-warn>
            <div class="text-red-500">
              Store name must be less than 20 characters
            </div>
          </template>
        </vs-input>

        <vs-button
          block
          @click="onUpdateStoreButtonClick"
          :loading="storeCreateLoading"
        >
          Update
        </vs-button>
      </div>

      <div v-else-if="store === null" class="w-full flex flex-col gap-4">
        <p class="text-2xl font-bold text-center w-full">Create a store</p>
        <vs-input block v-model="name" placeholder="Store Name">
          <template #icon>
            <i class="bx bx-text" />
          </template>

          <template v-if="name && name.length < 4" #message-danger>
            <div class="text-red-500">
              Store name must be at least 4 characters
            </div>
          </template>
          <template v-if="name && name.length > 20" #message-warn>
            <div class="text-red-500">
              Store name must be less than 20 characters
            </div>
          </template>
        </vs-input>

        <vs-button
          block
          @click="onCreateStoreButtonClick"
          :loading="storeCreateLoading"
        >
          Create
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { createStore, getMyStore, updateStore } from "@/services/ApiService";

export default {
  name: "Store",
  data() {
    return {
      store: undefined,
      name: "",
      storeCreateLoading: false,
    };
  },
  methods: {
    onCreateStoreButtonClick() {
      this.storeCreateLoading = true;
      createStore({ name: this.name })
        .then(() => {
          this.storeCreateLoading = false;
          this.getMyStore();
          this.$vs.notification({
            name: "Success",
            text: "Store created successfully",
            color: "success",
            position: "bottom-right",
          });
        })
        .catch(() => {
          this.storeCreateLoading = false;
          this.$vs.notification({
            name: "Error",
            text: "Error creating store",
            color: "danger",
            position: "bottom-right",
          });
        });
    },
    onUpdateStoreButtonClick() {
      this.storeCreateLoading = true;
      updateStore(this.store.slug, { name: this.name })
        .then(() => {
          this.storeCreateLoading = false;
          this.getMyStore();
          this.$vs.notification({
            name: "Success",
            text: "Store updated successfully",
            color: "success",
            position: "bottom-right",
          });
        })
        .catch(() => {
          this.storeCreateLoading = false;
          this.$vs.notification({
            name: "Error",
            text: "Error updating store",
            color: "danger",
            position: "bottom-right",
          });
        });
    },
    async getMyStore() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });
      const store = await getMyStore();
      if (store) {
        this.store = store;
        this.name = store.name;
      } else {
        this.store = null;
      }
      loading.close();
    },
  },
  async created() {
    await this.getMyStore();
  },
};
</script>

<style scoped></style>
